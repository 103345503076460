<template>
  <div class="container-fliud">
    <Header></Header>
    <h4 class="faq">Activities Trainings</h4>
   
<div class="gray">
   <div class="container">
   <div  v-for="activity in activities" :key="activity.id" >
       <router-link :to="'/training/' + activity.id" style="color:#000; text-decoration:none">
       <div class="row" id="row">
            <div class="col-md-8" >
              <h4> {{activity.title}}</h4>
              <p >
              {{activity.description}}
              </p>
              <div class="group">
                  <h6 class="date">Date: <span>{{activity.startDate}}</span></h6>
                  <h6>Venue: <span> {{activity.venue}}</span></h6>
              </div>
            </div>
              <div class="col-md-4">
              <img :src="ImgUrl+activity.imageFilesUploadId" class="img" alt="">
            </div>
         </div>   
       </router-link>
   </div>
   
   </div>
</div>
    <Footer></Footer>
  </div>
</template>

<script>
// @ is an alias to /src
import Header from '../components/Navbar';
import Footer from '../components/footer'
import baseUrl from '../baseUrl'
import appConfig from "../appConfig";

export default {
  name: 'Home',
  components: {
    Header,
   Footer
  },
    data(){
      return{
         activeTab:'activities',
          activities:[],
          imageSrc: appConfig.baseUrl,
          singleTraining:[],
          id: this.$route.params.id
      }
    },
 

  mounted() {
    baseUrl.get(`/api/getActivityTrainings/${this.id}`)
    .then((res)=>{
      this.activities = res.data
    }).catch((err)=>{
      console.log(err)
    })
  },
}
</script>
<style scoped>
*{
  font-family: 'Roboto', sans-serif; 
}
.faq{
  margin-top: 170px;
 margin-left: 50px;
 margin-bottom: 10px;
 font-family: 'Roboto', sans-serif; 
  color: #98C149;
}
.gray{
  border-top: 1px solid rgb(173, 173, 173);
  background-color: #F5F7FA;
  font-family: 'Roboto', sans-serif; 
  padding: 80px;
}
#row{
  background-color: #fff;
  margin-bottom: 40px;
  border-radius: 10px;
}
.img{
  width: 400px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}
.group{
  display: flex;
}
.date{
    margin-right: 40px;
}
h4{
    margin-top: 50px;
}
h6{
    color: #98C149;
}
span{
    color: #000;
}
</style>